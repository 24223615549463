const enrichOne = ({ value, links = [] }) => {
    const regex = /\[\[([^[\]]+)\]\[([^[\]]+)\]\]/g
    const withStyles = (value || '').replace(regex, '<span class="$2">$1</span>').replace(/\n/g, '<br />')
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g

    let result = withStyles.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`
    })

    links.forEach((link) => {
        const linkRegex = new RegExp(`\\b${link.title}\\b`, 'g')
        result = result.replace(linkRegex, `<a href="${link.url}" target="_blank">${link.title}</a>`)
    })

    return result
}

export const Enrich = ({ className = '', children = '', links = [] }) => {
    // [[ANY TEXT][className]]

    const array = Array.isArray(children) ? children : [children]
    const enriched = array.map((v) => enrichOne({ value: v, links })).join('')
    return <div className={className} dangerouslySetInnerHTML={{ __html: enriched }} />
}

export default Enrich
